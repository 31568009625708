@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: 'Cinzel';
  src: url('../public/font/Cinzel-VariableFont_wght.ttf') format('woff2'),
       url('../public/font/Cinzel-VariableFont_wght.ttf') format('woff');
  font-weight: regular;
  font-style: normal;
}


@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
@tailwind variants;


.font-playfair {
  font-family: 'Playfair Display', serif;
}

.font-bebasneue {
  font-family: 'Bebas Neue', sans-serif;
}

.font-raleway {
  font-family: 'Raleway', sans-serif;
}

.font-cinzel {
  font-family: 'Cinzel', serif;
}

.font-montserrat {
  font-family: 'Montserrat', sans-serif;
}

.grow { 
  transition: all .2s ease-in-out; 
}

.grow:hover { 
  transform: scale(1.1); 
}

.work {
  scroll-margin: 32px;
}